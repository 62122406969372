export default function body(props) {

  if (props.language === 'se') {
    return (
      <div>
        <div className="full-width">
        <h2>Vanliga frågor och svar</h2>
        </div>
        <div className="container">
        <ul>
            <li>
              <h3>Varför skulle någon lämnna tillbaka det dom hittar till ägaren ?</h3>
              <p>
                För att den som hittar din förlorade sak får en hittelön om dom lämnar
                tillbaka den. Förhoppningsvis har du ett bra lösenord på din dator eller
                telefon så dom blir inte så använbara för den som hittar dom. Om du inte
                har ett starkt lösenord på din telefon eller laptop rekomenderar vi att du
                skaffar ett nu.
              </p>
            </li>
            <li>
              <h3>Hur fungerar det ?</h3>
              <p>
                Du kommer att få ett klistermärke med en QR kod som du sätter på din kära
                ägodel. Personen som hittar ditt föremål skannar QR koden med deras enhet
                och får upp en sida här på return2us, på den sidan finns din information
                som du har skrivit in, om hur personen som hittat din kära egodel, kan
                kontakta dig. När din ägodel är returnerad till dig så får personen som
                hittat den sin hittelön.
              </p>
            </li>
            <li>
              <h3>
                Jag vill ha det här på min telefon, hur ska personen som hittar den
                kontakta mig ?
              </h3>
              <p>
                Nej, du ska inte lämna telefon nummret till din mobil om du använder
                tjänsten för din mobil. Vi rekomenderar att lämna nummret till en nära vän
                eller släkting som har möjlighet att ta imot din telefon om den blir
                borttappad. Vi rekomenderar dock att du skriver ditt namn så att personen
                som hittat telefonen kan säga vem telefonen tillhör.
              </p>
            </li>
            <li>
              <h3>Hur man läser en QR kod</h3>
              <p>
                I vanliga fall så är det bara att öppna kameran och sikta på QR koden,
                inom någon sekund bör adressen komma upp som man kan klicka på, men det är
                olika för olika telefoner. HelloTech har skrivit en bra artikel om
                detta:<br />
                <a
                  href="https://www.hellotech.com/guide/for/how-to-scan-qr-code-iphone-android"
                  target="_blank">
                    How to Scan a QR Code on an iPhone or Android
                </a>.<br />
                eller Huawei kund support:
                <a
                  href="https://consumer.huawei.com/en/community/details/How-to-Scan-QR-code-on-Android/topicId_141887/"
                  target="_blank">
                    How to Scan QR code on Android.
                </a>
              </p>
            </li>
            <li>
              <h3>Hur är det med "Find my phone" tjänsten</h3>
              <p>
                Om du inte har den tjänsten igång, så rekomenderar vi att ni slår på den,
                vänligen sök telefonens kundservice eller instruktionsbok för att se hur
                man slår på den. Den kommer att hjälpa dig att hitta din telefon, å länge
                som din telefon har batteri och du behöver tillgång till en dator. Men
                tyvärr håller batteriet i dagens telefoner så länge och om någon hittar
                den och tar den eller flyttar på den så blir det svårt. Men om du har en
                QR kod från return2us på telefonen så spelar det ingen roll om batteriet
                är slut. Då kan personen som hittar din telefon kontakta dig och retunera
                telefonen.
              </p>
            </li>
            <li>
              <h3>Men AppleCare+ ?</h3>
              <p>
                <a href="https://www.apple.com/support/products/iphone/" target="_blank"
                  >AppleCare+</a
                >
                är bara en bättre service från Apple om du har problem, men dom har en
                tjänst som man kan köpa till, <b>Theft and loss Coverage</b>, med denna
                tjänst får du en ny telefon om den blir borttappad eller stulen. Paketet
                kostar $ 7.49 per månad (IPhone SE) och $13.49 (IPhone 11-12 Pro/Pro Max).
                Om det känns som ett bättre val för er så ska ni såklart ta det. Se bara
                till att hålla din telefon säkerhets kopierad så ni inte förlorar
                innehållet.<b>OBS! Priserna kan ändras.</b>
              </p>
            </li>
          </ul>
        </div>     
      </div>
    )
  } else {
    return (
      <div>
      <h2>Frequently asked question (FAQ)</h2>
      <ul>
        <li>
          <h3>Why would some return a phone, laptop or other things they find ?</h3>
          <p>
            Because the person that finds your thing, will get a finders fee for it.
            Hopefully your phone or laptop will have a password and it will not be to
            much use for them. If you don\'t have a password, we strongly recommend
            that you create one now.
          </p>
        </li>
        <li>
          <h3>How does it work ?</h3>
          <p>
            You will get a sticker with a QR code on it and put it on your precious
            thing. The person that finds it will scan the QR code and their device
            will open a page here on return2us. On that page, you have entered
            information on how to connect to you. When you precious thing is returned
            to you, they will get the finders fee.
          </p>
        </li>
        <li>
          <h3>I want this for my phone, how should I be contacted if I lose it ?</h3>
          <p>
            Yes, you should not leave your phone number in the contact information for
            the person who finds your lost phone. We recommend the leave a contact
            number to a close friend or family member. You should still write your
            name as the owner so the person who finds your phone and contacts your
            close person can tell that person that it is your phone that they have
            found.
          </p>
        </li>
        <li>
          <h3>How to read a QR code</h3>
          <p>
            Usally it\'s just to open your camera app and point at the QR code, but it
            might be diffrent on some phones. HelloTech has a good article on this:<br />
            <a
              href="https://www.hellotech.com/guide/for/how-to-scan-qr-code-iphone-android"
              target="_blank"
              >How to Scan a QR Code on an iPhone or Android</a>.<br />
            or Huawei consumer support:
            <a
              href="https://consumer.huawei.com/en/community/details/How-to-Scan-QR-code-on-Android/topicId_141887/"
              target="_blank"
              >How to Scan QR code on Android.</a
            >
          </p>
        </li>
        <li>
          <h3>What about the "Find my phone" service</h3>
          <p>
            if you don’t have this feature turned on, we strongly recommend doing it
            now. It will help you find your phone as long as it has battery. But
            sadly, battery life of a mobile phone is not that great. And someone might
            find it and take it. Sense return2us uses a sticker, it doesn\’t matter if
            the battery is dead, the finder will scan it and be able to contact you so
            you can get it back.
          </p>
        </li>
        <li>
          <h3>What about AppleCare+</h3>
          <p>
            <a href="https://www.apple.com/support/products/iphone/" target="_blank">AppleCare+</a>
            is just a better service from Apple, but they have an extra feature that
            you can add to it, <b>Theft and loss Coverage</b>, which will replace your
            phone if it gets stolen.That package costs $ 7.49 a month (IPhone SE) and
            $13.49 (IPhone 11-12 Pro/Pro Max). If that feels like a better solution
            for you then you should take it.<b
              >Disclosure Prices might have changed.</b
            >
          </p>
        </li>
      </ul>
      </div>
    )
  }
}