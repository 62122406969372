const Lang = {
  'en': {
    'header':'Have any of you empolyes ever lost something?',
    'lead': 'Return2us, Returning your stuff to you.',
    'header2':'How does it work ?',
    'body' : '<p>First you register you item here. You will get an Qr code to put on your item and if you lose it, and when someone some finds it, they will scan the Qr code and they can contact you and you can get your item back. Why would they do that you might think ? Because there is a finders fee. The person that finds you item will earn money off it.<br/><b>How much is it worth to you to get your mobile or laptop returned to you if you lost it ? </b></p>'
  },
  'se': {
    'header':'Har någon av era anställda förlorat något?',
    'lead': 'Return2us, returnerar saker.',
    'header2':'Hur fungerar det ?',
    'body' : '<p>Först registerar ni eran sak här. Ni får en klisterlapp med en QR kod som ni sätter på er sak och om den förloras och någon hittar den. Så kan dom scanna QR koden och komma i kontakt med er för att lämna tillbaka den sak dom hittat. Då kanske ni undrar varför skulle dom göra det ? för att det är en hittelön till den som lämnar tillbaka det dom hittat som är registerad hos Return2us. <b>Hur mycket är det värt att få tillbaka ett förlorat föremål hos er, t ex en laptop ?</b></p>'
  }
}

export default Lang;