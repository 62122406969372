import './Home.css';
import React, { useEffect,useState } from "react";
import parse from "html-react-parser";
import lang from './Lang';
import Body from "./Body";

export default function Home(props) {

  const [currLang, setcurrLang] = useState(lang['en']);
  const [currPrice, setcurrPrice] = useState(lang['en']);

  useEffect(() => {
    if (props.language) {
      setcurrLang(lang[props.language]);
      setcurrPrice(props.price[props.language]);
    }
  }, [props.language]);

  if(currLang === undefined) return ( <></>); // TODO: better fix for iphone

  return (
    <div id="home" >
      <div className="container">
       <div><h2>{currLang.header}</h2></div>
      </div>
      <Body language={props.language} />
      <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h2 className="display-4">{currLang.pricing}</h2>
      </div>
      <div className="container">
        <div className="card-deck mb-3">
          <div className="card mb-4">
            <h4 className="my-0 font-weight-normal">{currLang.single}</h4>
            <div className="card-body">
              <h2 className="card-title pricing-card-title"><span className="price">{currPrice.single.month}</span> {currPrice.sign}<small className="text-muted">/ {currLang.m}<span className="upper">*</span></small></h2>
              <h2 className="card-title pricing-card-title"><span className="price">{currPrice.single.year}</span> {currPrice.sign}<small className="text-muted">/ {currLang.y}</small></h2>
              {parse(currLang.singleBody)}
              <a className="btn btn-lg btn-block btn-action align-bottom" href="/user/register">{currLang.singleAction}</a>
            </div>
          </div>
          <div className="card mb-4">
            <h4 className="my-0 font-weight-normal">{currLang.tripple}</h4>
            <div className="card-body">
              <h2 className="card-title pricing-card-title"><span className="price">{currPrice.tripple.month}</span> {currPrice.sign} <small className="text-muted">/ {currLang.m}<span className="upper">*</span></small></h2>
              <h2 className="card-title pricing-card-title"><span className="price">{currPrice.tripple.year}</span> {currPrice.sign} <small className="text-muted">/ {currLang.y}</small></h2>
              {parse(currLang.trippleBody)}
              <a className="btn btn-lg btn-action btn-block" href="/user/register">{currLang.trippleAction}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="small-desc"><span className="upper">*</span>{currLang.explain}</div>
    </div>

  )
}