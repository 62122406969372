// TODO: Company name not registered, country ? Start with sweden ?? register a email
// translate this
export default function Terms(props) {

  if (props.language === 'se') {
    return (
      <div id="terms" className="container mt">
        <h1>Villkor</h1>
          <p>Våra användarvillkor uppdaterades senast 2022-04-15.
            Läs dessa villkor noggrant innan du använder vår tjänst.</p>
          <h2>Tolkning och definitioner</h2>
          <p><strong>Tolkning</strong></p>
          <p>Orden vars initiala bokstav är versal har betydelser definierade under följande villkor. 
            Följande definitioner ska ha samma betydelse oavsett om de förekommer i singular eller plural.</p>
          <p><strong>Definitioner</strong></p>
          <p>För ändamålen med dessa villkor:</p>
          <ul>
          <li>
          <p><strong>"Affiliate"</strong> avser en enhet som kontrollerar, 
          kontrolleras av eller är under gemensam kontroll med en part, 
          där "kontroll" betyder ägande av 50 % eller mer av aktierna, 
          ägarandelen eller andra värdepapper som har rätt att rösta för val av styrelseledamöter 
          eller annan förvaltningsmyndighet.</p>
          </li>
          <li>
          <p><strong>"Konto"</strong> betyder ett unikt konto som skapats för att du ska få tillgång 
          till vår Tjänst eller delar av vår Tjänst.</p>
          </li>
          <li>
          <p><strong>"Företaget"</strong> (referat till som antingen "företaget", 
          "vi", "oss" eller "vårt" i detta avtal) hänvisar till Return2us</p>
          </li>
          <li>
          <p><strong>"Land"</strong> syftar på Sverige.</p>
          </li>
          <li>
          <p><strong>"Innehåll"</strong> syftar på innehåll som text, 
          bilder eller annan information som kan läggas upp, laddas upp, 
          länkas till eller på annat sätt göras tillgängligt av dig, oavsett formen på innehållet.</p>
          </li>
          <li>
          <p><strong>"Enhet"</strong> avser vilken enhet som helst som kan komma åt Tjänsten såsom en dator, 
          en mobiltelefon eller en digital surfplatta.</p>
          </li>
          <li>
          <p><strong>"Feedback"</strong> betyder feedback, innovationer eller förslag som skickas av dig angående attribut, 
          prestanda eller funktioner hos vår tjänst.</p>
          </li>
          <li>
          <p><strong>"Tjänst"</strong> hänvisar till webbplatsen.</p>
          </li>
          <li>
          <p><strong>"Användarvillkor och vilkor"</strong> avser dessa Villkor som utgör hela avtalet 
          mellan dig och företaget angående användningen av tjänsten.</p>
          </li>
          <li>
          <p><strong>"Tredje parts sociala medietjänst"</strong> avser alla tjänster eller innehåll 
          (inklusive data, information, produkter eller tjänster) som tillhandahålls av en tredje part som kan visas, 
          inkluderas eller göras tillgängligt av tjänsten.</p>
          </li>
          <li>
          <p><strong>"Webbplats"</strong> hänvisar till retur2.us, tillgänglig från https://retur2.us</p>
          </li>
          <li>
          <p><strong>"Du"</strong> avser individen som har åtkomst till eller använder Tjänsten, 
          eller företaget eller annan juridisk person på uppdrag av vilken sådan individ använder eller använder Tjänsten, 
          beroende på vad som är tillämpligt.</p>
          </li>
          </ul>
          <p><strong>Bekräftelse</strong></p>
          <p>Dessa är villkoren som styr användningen av denna tjänst och avtalet som gäller mellan dig och företaget. 
            Dessa villkor anger rättigheterna och skyldigheterna för alla användare angående användningen av tjänsten.</p>
          <p>Din åtkomst till och användning av tjänsten är villkorad av att du accepterar och följer dessa villkor. 
            Dessa villkor gäller för alla besökare, användare och andra som har tillgång till eller använder tjänsten.</p>
          <p>Genom att komma åt eller använda tjänsten samtycker du till att vara bunden av dessa villkor. 
            Om du inte håller med om någon del av dessa villkor kan du inte komma åt tjänsten.</p>
          <p>Du intygar att du är över 18 år. Företaget tillåter inte att personer under 18 år använder tjänsten.</p>
          <p>Din åtkomst till och användning av tjänsten är också villkorad av att du accepterar och följer företagets integritetspolicy. 
            Vår sekretesspolicy beskriver våra policyer och procedurer för insamling, 
            användning och avslöjande av din personliga information när du använder applikationen eller webbplatsen och berättar 
            om dina integritetsrättigheter och hur lagen skyddar dig. Läs vår integritetspolicy noggrant innan du använder vår tjänst.</p>
          <p><strong>Användarkonton</strong></p>
          <p>När du skapar ett konto hos oss måste du tillhandahålla oss information som alltid är korrekt, 
            fullständig och aktuell. Underlåtenhet att göra det utgör ett brott mot villkoren, 
            vilket kan leda till omedelbar uppsägning av ditt konto på vår tjänst.</p>
          <p>Du är ansvarig för att skydda lösenordet som du använder för att komma åt tjänsten och för alla aktiviteter eller åtgärder under ditt lösenord, 
            oavsett om ditt lösenord finns med vår tjänst eller en social mediatjänst från tredje part.</p>
          <p>Du samtycker till att inte avslöja ditt lösenord till någon tredje part. 
            Du måste meddela oss omedelbart när du blir medveten om något säkerhetsbrott eller obehörig användning av ditt konto.</p>
          <p>Du får inte som användarnamn använda namnet på en annan person eller enhet eller som inte är lagligt tillgängligt för användning, 
            ett namn eller varumärke som är föremål för någon annan persons eller enhets rättigheter utan lämplig auktorisation, 
            eller ett namn som är annars kränkande, vulgärt eller obscent.</p>
          <h2>Innehåll</h2>
          <p><strong>Din rätt att lägga upp innehåll</strong></p>
          <p>Vår tjänst låter dig lägga upp innehåll. Du är ansvarig för innehållet som du lägger upp på tjänsten, 
            inklusive dess laglighet, tillförlitlighet och lämplighet.</p>
          <p>Genom att publicera innehåll till tjänsten ger du oss rätten och licensen att använda, 
            modifiera, offentligt utföra, offentligt visa, reproducera och distribuera sådant innehåll på och genom tjänsten. 
            Du behåller alla dina rättigheter till innehåll du skickar, lägger upp eller visar på eller via tjänsten och du är ansvarig för att skydda dessa rättigheter. 
            Du samtycker till att denna licens inkluderar rätten för oss att göra ditt innehåll tillgängligt för andra användare av tjänsten, 
            som också kan använda ditt innehåll enligt dessa villkor.</p>
          <p>Du intygar och garanterar att: 
            (i) innehållet är ditt (du äger det) eller att du har rätt att använda det och ge oss rättigheterna och licensen enligt dessa villkor, och 
            (ii) publiceringen av ditt innehåll på eller genom tjänsten bryter inte mot någon persons integritetsrättigheter, 
            publicitetsrättigheter, upphovsrätter, avtalsrättigheter eller andra rättigheter.</p>
          <p><strong>Innehållsbegränsningar</strong></p>
          <p>Företaget ansvarar inte för innehållet hos Tjänstens användare. 
            Du förstår och samtycker uttryckligen att du är ensam ansvarig för innehållet och för all aktivitet som sker under ditt konto, 
            oavsett om det görs av dig eller någon tredje person som använder ditt konto.</p>
          <p>Du får inte överföra något innehåll som är olagligt, stötande, upprörande, avsett att äckla, hotfullt, 
            ärekränkande, obscent eller på annat sätt stötande. Exempel på sådant stötande innehåll inkluderar, 
            men är inte begränsade till, följande:</p>
          <ul>
          <li>Olaglig eller främjande av olaglig verksamhet.</li>
          <li>Ärekränkande, diskriminerande eller elakt innehåll, inklusive referenser eller kommentarer om religion, 
            ras, sexuell läggning, kön, nationellt/etniskt ursprung eller andra riktade grupper.</li>
          <li>Spam, maskin – eller slumpmässigt – genererad, som utgör otillåten eller oönskad reklam, kedjebrev, 
            någon annan form av otillåten uppmaning eller någon form av lotteri eller hasardspel.</li>
          <li>Innehåller eller installerar virus, maskar, skadlig programvara, trojanska hästar eller 
            annat innehåll som är utformat eller avsett att störa, skada eller begränsa funktionen hos mjukvara, hårdvara eller 
            telekommunikationsutrustning eller för att skada eller få obehörig åtkomst till data eller annat information om en tredje person.</li>
          <li>Intrång i någon parts äganderätt, inklusive patent, varumärke, affärshemlighet, upphovsrätt, publicitetsrätt eller andra rättigheter.</li>
          <li>Utger sig att vara någon person eller enhet inklusive företaget och dess anställda eller representanter.</li>
          <li>Kränker tredje persons integritet.</li>
          <li>Falsk information och funktioner.</li>
          </ul>
          <p>Företaget förbehåller sig rätten, men inte skyldigheten, att, efter eget gottfinnande, 
            avgöra om något innehåll är lämpligt och följer dessa villkor, vägra eller ta bort detta innehåll. 
            Företaget förbehåller sig vidare rätten att göra formatering och redigeringar och ändra sättet för innehållet. 
            Företaget kan också begränsa eller återkalla användningen av tjänsten om du publicerar sådant stötande innehåll.</p>
          <p>Eftersom företaget inte kan kontrollera allt innehåll som publiceras av användare och/eller tredje part på tjänsten, 
            samtycker du till att använda tjänsten på egen risk. Du förstår att du genom att använda tjänsten kan utsättas för 
            innehåll som du kan tycka är stötande, oanständigt, felaktigt eller stötande, och du samtycker till att 
            företaget under inga omständigheter kommer att hållas ansvarigt på något sätt för något innehåll, 
            inklusive eventuella fel eller utelämnanden i något innehåll, eller någon förlust eller skada av något slag som 
            uppstår till följd av din användning av något innehåll.</p>
          <p><strong>Säkerhetskopiering av innehåll</strong></p>
          <p>Även om regelbundna säkerhetskopieringar av innehåll utförs, 
            garanterar företaget inte att det inte kommer att ske någon förlust eller korruption av data.</p>
          <p>Korrupta eller ogiltiga säkerhetskopieringspunkter kan orsakas av, utan begränsning, 
            innehåll som är skadat innan det säkerhetskopieras eller som ändras under tiden en säkerhetskopiering utförs.</p>
          <p>Företaget kommer att tillhandahålla support och försöka felsöka alla kända eller upptäckta problem som kan påverka 
            säkerhetskopiering av innehåll. Men du erkänner att företaget inte har något ansvar relaterat till innehållets 
            integritet eller misslyckandet med att framgångsrikt återställa innehållet till ett användbart tillstånd.</p>
          <p>You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.</p>
          <p><strong>Upphovsrättspolicy</strong></p>
          <p><strong>Immaterialrättsintrång</strong></p>
          <p>Vi respekterar andras immateriella rättigheter. Det är vår policy att svara på alla påståenden om att innehåll 
            som publiceras på tjänsten gör intrång i upphovsrätten eller andra immateriella intrång hos någon person.</p>
          <p>Om du är upphovsrättsinnehavare, eller auktoriserad på uppdrag av någon, 
            och du tror att det upphovsrättsskyddade verket har kopierats på ett sätt som utgör upphovsrättsintrång som äger rum genom tjänsten, 
            måste du skicka in ditt meddelande skriftligen till kännedom av vårt upphovsrättsombud via e-post ([Info@digitalconcept.se]) 
            och inkludera i ditt meddelande en detaljerad beskrivning av det påstådda intrånget.</p>
          <p>Du kan hållas ansvarig för skador (inklusive kostnader och advokatarvoden) för att felaktigt framställa att något innehåll gör intrång i din upphovsrätt.</p>
          <p><strong>DMCA-meddelande och DMCA-förfarande för anspråk på upphovsrättsintrång</strong></p>
          <p>Du kan skicka ett meddelande i enlighet med Digital Millennium Copyright Act (DMCA) genom att ge vår upphovsrättsagent
             följande information skriftligen (se 17 U.S.C 512(c)(3) för ytterligare information):</p>
          <ul>
          <li>En elektronisk eller fysisk signatur av den person som är behörig att agera på uppdrag av ägaren av upphovsrättens intresse.</li>
          <li>En beskrivning av det upphovsrättsskyddade verket som du hävdar har gjorts intrång, 
            nklusive webbadressen (d.v.s. webbsidans adress) till platsen där det upphovsrättsskyddade verket finns eller 
            en kopia av det upphovsrättsskyddade verket.</li>
          <li>Identifiering av webbadressen eller annan specifik plats på tjänsten där materialet som du hävdar gör intrång finns.</li>
          <li>Din adress, telefonnummer och e-postadress.</li>
          <li>Ett uttalande från dig om att du i god tro tror att den omtvistade användningen inte är godkänd av upphovsrättsinnehavaren, 
            dess ombud eller lagen. Ett uttalande från dig, gjort under straff för mened, 
            att ovanstående information i ditt meddelande är korrekt och att du är upphovsrättsinnehavaren eller 
            behörig att agera å upphovsrättsinnehavarens vägnar.</li>
          </ul>
          <p>Du kan kontakta vårt upphovsrättsombud via e-post ([Info@digitalconcept.se]). Efter mottagandet av ett meddelande kommer företaget att vidta alla åtgärder, 
            efter eget gottfinnande, det anser lämpliga, inklusive borttagning av det ifrågasatta innehållet från tjänsten.</p>
          <p><strong>Immateriella rättigheter</strong></p>
          <p>Tjänsten och dess ursprungliga innehåll (exklusive innehåll som tillhandahålls av dig eller andra användare), 
            funktioner och funktionalitet är och kommer att förbli företagets och dess licensgivares exklusiva egendom.</p>
          <p>Tjänsten är skyddad av upphovsrätt, varumärken och andra lagar i både landet och utlandet.</p>
          <p>Våra varumärken och klädsel får inte användas i samband med någon produkt eller tjänst utan föregående skriftligt medgivande från företaget.</p>
          <p><strong>Din feedback till oss</strong></p>
          <p>Du tilldelar alla rättigheter, titel och intressen i all feedback du ger företaget. Om en sådan överlåtelse av någon anledning är ineffektiv, 
            samtycker du till att ge företaget en icke-exklusiv, evig, oåterkallelig, royaltyfri, världsomspännande rättighet och licens att använda, 
            reproducera, avslöja, underlicensiera, distribuera, modifiera och utnyttja sådan feedback utan restriktion.</p>
          <p><strong>Länkar till andra webbplatser</strong></p>
          <p>Företaget har ingen kontroll över, och tar inget ansvar för, innehållet, integritetspolicyn eller praxis på tredje parts webbplatser eller tjänster. 
            Du erkänner och samtycker vidare till att företaget inte ska vara ansvarigt eller ansvarigt, direkt eller indirekt, 
            för någon skada eller förlust som orsakats eller påstås vara orsakad av eller i samband med användningen av eller beroendet av sådant innehåll, 
            varor eller tjänster som är tillgängliga på eller via sådana webbplatser eller tjänster.</p>
          <p>Vi rekommenderar starkt att du läser villkoren och integritetspolicyn för alla tredjepartswebbplatser eller tjänster som du besöker.</p>
          <p><strong>Avstängning</strong></p>
          <p>Vi kan avsluta eller stänga av ditt konto omedelbart, utan föregående meddelande eller ansvar, 
            oavsett anledning, inklusive utan begränsning om du bryter mot dessa villkor.</p>
          <p>Vid avstängning upphör din rätt att använda Tjänsten omedelbart.</p>
          <p><strong>Ansvarsbegränsning</strong></p>
          <p>Oavsett eventuella skador som du kan ådra dig, ska företagets och alla dess leverantörers hela ansvar enligt någon 
            bestämmelse i dessa villkor och din exklusiva gottgörelse för allt det föregående begränsas till det belopp
             som faktiskt betalats av dig genom tjänsten minus kostnader av det som har används av dig.</p>
          <p>I den utsträckning det är tillåtet enligt tillämplig lag ska företaget eller dess leverantörer under inga omständigheter 
            hållas ansvariga för några speciella, tillfälliga, indirekta eller följdskador (inklusive, men inte begränsat till, 
            skador för förlust av vinst, förlust av data eller annan information, för affärsavbrott, för personskada, 
            förlust av integritet som härrör från eller på något sätt relaterat till användningen av eller oförmågan att använda tjänsten, 
            programvara från tredje part och/eller hårdvara från tredje part som används med tjänsten, 
            eller på annat sätt i samband med någon bestämmelse i dessa villkor), även om företaget eller någon leverantör har 
            informerats om möjligheten till sådana skador och även om åtgärden misslyckas med sitt väsentliga syfte.</p>
          <p>Vissa stater tillåter inte uteslutning av underförstådda garantier eller begränsning av ansvar för oförutsedda skador eller följdskador, 
            vilket innebär att vissa av ovanstående begränsningar kanske inte gäller. 
            I dessa stater kommer varje dels ansvar att begränsas i den utsträckning som lagen tillåter.</p>
          <p><strong>Ansvarsfriskrivning "I BEFINTLIGT SKICK" och "SOM TILLGÄNGLIG".</strong></p>
          <p>Tjänsten tillhandahålls dig "I BEFINTLIGT SKICK" och "SOM TILLGÄNGLIG" och med alla fel och defekter utan garanti av något slag. 
            I den maximala omfattning som tillåts enligt tillämplig lag, frånsäger sig företaget, 
            för sina egna vägnar och på sina dotterbolags vägnar och dess respektive deras respektive licensgivare och tjänsteleverantörer, 
            uttryckligen alla garantier, vare sig uttryckliga, underförstådda, lagstadgade eller på annat sätt, med avseende på Tjänst, 
            inklusive alla underförstådda garantier om säljbarhet, lämplighet för ett visst ändamål, titel och icke-intrång, 
            och garantier som kan uppstå ur affärer, prestanda, användning eller handelspraxis. 
            Utan begränsning till det föregående ger företaget inga garantier eller åtaganden, och gör inga utfästelser av något slag att tjänsten kommer att uppfylla dina krav, 
            uppnå avsedda resultat, vara kompatibel eller fungera med någon annan programvara, applikationer, system eller tjänster, 
            fungera utan avbrott, uppfylla prestanda- eller tillförlitlighetsstandarder eller 
            vara felfri eller att eventuella fel eller defekter kan eller kommer att korrigeras.</p>
          <p>Utan att begränsa det föregående ger varken företaget eller någon av företagets leverantörer några utfästelser eller garantier av något slag, 
            uttryckliga eller underförstådda: 
            (i) angående tjänstens funktion eller tillgänglighet, eller information, 
            innehåll och material eller produkter inkluderad därpå; 
            (ii) att tjänsten kommer att vara oavbruten eller felfri; 
            (iii) om riktigheten, tillförlitligheten eller valutan för all information eller innehåll som tillhandahålls via tjänsten; eller 
            (iv) att tjänsten, dess servrar, innehållet eller e-postmeddelanden som skickas från eller på uppdrag av företaget är fria från virus, 
            skript, trojanska hästar, maskar, skadlig programvara, tidsbomber eller andra skadliga komponenter.</p>
          <p>Vissa jurisdiktioner tillåter inte uteslutning av vissa typer av garantier eller begränsningar av tillämpliga lagstadgade 
            rättigheter för en konsument, så vissa eller alla av ovanstående undantag och begränsningar kanske inte gäller dig. 
            Men i ett sådant fall ska undantagen och begränsningarna som anges i detta avsnitt tillämpas i den utsträckning som är 
            verkställbar enligt tillämplig lag.</p>
          <p><strong>Gällande lag</strong></p>
          <p>Lagarna i landet, exklusive dess lagkonflikter, ska styra dessa villkor och din användning av tjänsten. 
            Din användning av applikationen kan också vara föremål för andra lokala, statliga, nationella eller internationella lagar.</p>
          <p><strong>Tvistlösning</strong></p>
          <p>Om du har någon oro eller tvist om tjänsten, samtycker du till att först försöka lösa tvisten informellt genom att kontakta företaget.</p>
          <p><strong>För användare av Europeiska unionen (EU).</strong></p>
          <p>Om du är konsument inom EU kommer du att dra nytta av alla obligatoriska bestämmelser i lagen i det land där du är bosatt.</p>
          <p><strong>USA:s lagliga efterlevnad</strong></p>
          <p>Du representerar och garanterar att (i) du inte befinner dig i ett land som är föremål för USA:s regeringsembargo, 
            eller som har utsetts av USA:s regering som ett "terroriststödjande" land, och (ii) du inte är listad på någon av 
            Förenta staternas regeringslista över förbjudna eller begränsade parter.</p>
          <h2>Avskiljbarhet och avstående</h2>
          <p><strong>Avskiljbarhet</strong></p>
          <p>Om någon bestämmelse i dessa villkor anses vara omöjlig att verkställa eller ogiltig, kommer en sådan bestämmelse att ändras och tolkas för att uppnå målen för denna bestämmelse i största möjliga utsträckning enligt tillämplig lag och de återstående bestämmelserna kommer att fortsätta med full kraft och effekt.</p>
          <p><strong>Avstående</strong></p>
          <p>Förutom vad som anges häri ska underlåtenhet att utöva en rättighet eller att kräva fullgörande av en skyldighet enligt dessa villkor inte påverka en parts möjlighet att utöva en sådan rättighet eller kräva ett sådant utförande när som helst därefter, och inte heller ska avståendet från ett brott utgöra ett avstående. eventuella efterföljande överträdelser.</p>
          <p><strong>Översättning Tolkning</strong></p>
          <p>Dessa villkor kan ha översatts om vi har gjort dem tillgängliga för dig på vår tjänst.<br/>
Du samtycker till att den engelska originaltexten ska ha företräde i händelse av en tvist.</p>
          <p><strong>Ändringar av dessa villkor</strong></p>
          <p>Vi förbehåller oss rätten att, efter eget gottfinnande, ändra eller ersätta dessa villkor när som helst. 
            Om en revidering är väsentlig kommer vi att göra rimliga ansträngningar för att ge minst 30 dagars varsel innan nya villkor träder i kraft.
             Vad som utgör en väsentlig förändring kommer att bestämmas efter eget gottfinnande.</p>
          <p>Genom att fortsätta att komma åt eller använda vår tjänst efter att dessa ändringar har trätt i kraft, samtycker du till att vara bunden av de reviderade villkoren. 
            Om du inte godkänner de nya villkoren, helt eller delvis, vänligen sluta använda webbplatsen och tjänsten.</p>
          <p><strong>Kontakta oss</strong></p>
          <p>Om du har några frågor om dessa villkor kan du kontakta oss:</p>
          <ul>
          <li>Genom att besöka denna sida på vår hemsida: https://retur2.us/support</li>
          <li>Genom att skicka ett mejl till oss: <a href="mailto:info@digitalconcept.se">info@digitalconcept.se</a></li>
          </ul>
          <p></p>
      </div>
    )
  } else {
    return (
      <div>
        <h1>Terms and Conditions</h1>
          <p>Our Terms and Conditions were last updated on 2022-04-15.</p>
          <p>Please read these terms and conditions carefully before using Our Service.</p>
          <p><strong>Interpretation and Definitions</strong></p>
          <h2>Interpretation</h2>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. 
            The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <p><strong>Definitions</strong></p>
          <p>For the purposes of these Terms and Conditions:</p>
          <ul>
          <li>
          <p><strong>"Affiliate"</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
          </li>
          <li>
          <p><strong>"Account"</strong> means a unique account created for You to access our Service or parts of our Service.</p>
          </li>
          <li>
          <p><strong>"Company"</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Return2us.</p>
          </li>
          <li>
          <p><strong>"Country"</strong> refers to Sweden.</p>
          </li>
          <li>
          <p><strong>"Content"</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</p>
          </li>
          <li>
          <p><strong>"Device"</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
          </li>
          <li>
          <p><strong>"Feedback"</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</p>
          </li>
          <li>
          <p><strong>"Service"</strong> refers to the Website.</p>
          </li>
          <li>
          <p><strong>"Terms and Conditions"</strong> (also referred as "Terms" or "Terms of Use") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions Agreement was generated by <a href="https://www.termsfeed.com/blog/sample-terms-and-conditions-template/">TermsFeed Terms and Conditions Template</a>.</p>
          </li>
          <li>
          <p><strong>"Third-party Social Media Service"</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
          </li>
          <li>
          <p><strong>"Website"</strong> refers to retur2.us, accessible from https://retur2.us</p>
          </li>
          <li>
          <p><strong>"You"</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
          </li>
          </ul>
          <p><strong>Acknowledgment</strong></p>
          <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
          <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
          <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
          <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
          <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
          <p><strong>User Accounts</strong></p>
          <p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>
          <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
          <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>
          <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
          <h2>Content</h2>
          <p><strong>Your Right to Post Content</strong></p>
          <p>Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.</p>
          <p>By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.</p>
          <p>You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
          <p><strong>Content Restrictions</strong></p>
          <p>The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account.</p>
          <p>You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:</p>
          <ul>
          <li>Unlawful or promoting unlawful activity.</li>
          <li>Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.</li>
          <li>Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.</li>
          <li>Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.</li>
          <li>Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.</li>
          <li>Impersonating any person or entity including the Company and its employees or representatives.</li>
          <li>Violating the privacy of any third person.</li>
          <li>False information and features.</li>
          </ul>
          <p>The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner of any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content.<br/>
          As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.</p>
          <p><strong>Content Backups</strong></p>
          <p>Although regular backups of Content are performed, the Company does not guarantee there will be no loss or corruption of data.</p>
          <p>Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.</p>
          <p>The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.</p>
          <p>You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.</p>
          <p><strong>Copyright Policy</strong></p>
          <p><strong>Intellectual Property Infringement</strong></p>
          <p>We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.</p>
          <p>If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email ([Info@digitalconcept.se]) and include in Your notice a detailed description of the alleged infringement.</p>
          <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.</p>
          <p><strong>DMCA Notice and DMCA Procedure for Copyright Infringement Claims</strong></p>
          <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
          <ul>
          <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</li>
          <li>A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</li>
          <li>Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.</li>
          <li>Your address, telephone number, and email address.</li>
          <li>A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
          <li>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.</li>
          </ul>
          <p>You can contact our copyright agent via email ([Info@digitalconcept.se]). Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.</p>
          <p><strong>Intellectual Property</strong></p>
          <p>The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.</p>
          <p>The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.</p>
          <p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</p>
          <p><strong>Your Feedback to Us</strong></p>
          <p>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>
          <p><strong>Links to Other Websites</strong></p>
          <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
          <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
          <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
          <p><strong>Termination</strong></p>
          <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
          <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</p>
          <p><strong>Limitation of Liability</strong></p>
          <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
          <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
          <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
          <p><strong>"AS IS" and "AS AVAILABLE" Disclaimer</strong></p>
          <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
          <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
          <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
          <p><strong>Governing Law</strong></p>
          <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
          <p><strong>Disputes Resolution</strong></p>
          <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
          <p><strong>For European Union (EU) Users</strong></p>
          <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>
          <p><strong>United States Legal Compliance</strong></p>
          <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
          <h2>Severability and Waiver</h2>
          <p><strong>Severability</strong></p>
          <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
          <p><strong>Waiver</strong></p>
          <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
          <p><strong>Translation Interpretation</strong></p>
          <p>These Terms and Conditions may have been translated if We have made them available to You on our Service.<br/>
          You agree that the original English text shall prevail in the case of a dispute.</p>
          <p><strong>Changes to These Terms and Conditions</strong></p>
          <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
          <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
          <p><strong>Contact Us</strong></p>
          <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
          <ul>
          <li>By visiting this page on our website: https://retur2.us/support</li>
          <li>By sending us an email: info@digitalconcept.se</li>
          </ul>
          <p></p>
      </div>
    )
  }
}