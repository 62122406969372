import "./Support.css";
import React, { useEffect,useState } from "react";
import lang from './Lang';
import Body from "./Body";
export default function Support(props) {
  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);
  }, [props.language]);
  
  return (
    <div id="support">
      <div>
        <div>
          <h2>{currLang.header}</h2>
          <p className="lead">{currLang.lead}</p>
          <p>{currLang.answer}</p>
        </div>
      </div>
      <Body language={props.language} />
    </div>
  )
}
