import "./Companies.css";
import React, { useEffect,useState } from "react";
import parse from "html-react-parser";

import lang from './Lang';

export default function Cookies(props) {
  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);
  }, [props.language]);

  return (
    <div id="companies">
      <div>
        <div>
          <h2>{currLang['header']}</h2>
          <p className="lead">{currLang['lead']}</p>
          </div>
      </div>
      <div className="full-width">
        <h2>{currLang['header2']}</h2>
      </div>
      <div className="container">
        {parse(currLang['body'])}
      </div>
    </div>
  )
}
